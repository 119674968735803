<template>
  <div>
    <p v-if="loadingSpecialTxt">...loading data</p>
    <!-- Polishtype -->
    <b-form-select
      v-else
      :options="specicalTxtOptions"
      v-model="selectedOption"
      @change="updateSelectedOption"
    ></b-form-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SpecialTxtSelect",
  props: ["itemId", "selected", "rowId"],
  data() {
    return {
      selectedOption: this.selected,
      loadingSpecialTxt: false
    };
  },
  methods: {
    ...mapActions([""]),
    updateSelectedOption(value) {
      var itemUpdatedValue = {
        rowId: this.rowId,
        itemId: this.itemId,
        data: value
      };
      this.$emit("update-specialTxt", itemUpdatedValue);
    }
  },
  computed: {
    ...mapGetters(["allItems"]),
    specicalTxtOptions: {
      get() {
        if (this.loadingPolishTypes) {
          return null;
        }
        if (this.allItems.filter(x => x.id === this.itemId)[0].specialTxt) {
          return this.allItems
            .filter(x => x.id === this.itemId)[0]
            .specialTxt.map(x => {
              return { value: x.name, text: x.name };
            });
        }
        return null;
      }
    }
  },
  mounted() {
    this.loadingSpecialTxt = true;
    if (this.itemId) {
      this.loadingSpecialTxt = false;
    }
  }
};
</script>

<style></style>
