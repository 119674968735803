<template>
  <b-badge pill :variant="badgevariant">{{ type }}</b-badge>
</template>

<script>
export default {
  name: "PolishType",
  props: ["type"],
  computed: {
    badgevariant: {
      get() {
        if (this.$props.type === "Polished Long edges") {
          return "primary";
        } else if (this.$props.type === "NO Polishing") {
          return "warning";
        } else if (this.$props.type === "Polished Short edges") {
          return "danger";
        } else if (this.$props.type === "Polished ALL edges") {
          return "success";
        } else if (this.$props.type === "Polished Facet") {
          return "info";
        } else {
          return "light";
        }
      }
    }
  }
};
</script>

<style></style>
