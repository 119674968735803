<template>
  <div>
    <p v-if="loadingPolishTypes">...loading polish types</p>
    <!-- Polishtype -->
    <b-form-select
      v-else
      :options="polishOptions"
      v-model="selectedOption"
      @change="updateSelectedOption"
    ></b-form-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PolishOptionsInputSelect",
  props: ["itemId", "selected", "rowId"],
  data() {
    return {
      selectedOption: this.selected,
      loadingPolishTypes: false
    };
  },
  methods: {
    ...mapActions(["loadPolishTypeForItem"]),
    updateSelectedOption(value) {
      var itemUpdatedValue = {
        rowId: this.rowId,
        itemId: this.itemId,
        data: value
      };
      this.$emit("update-polishType", itemUpdatedValue);
    }
  },
  computed: {
    ...mapGetters(["getPolishTypesForItem"]),
    polishOptions: {
      get() {
        if (this.loadingPolishTypes) {
          return null;
        }
        return this.getPolishTypesForItem[this.itemId];
      }
    }
  },
  mounted() {
    this.loadingPolishTypes = true;
    if (this.itemId) {
      this.loadPolishTypeForItem(this.itemId).finally(() => {
        this.loadingPolishTypes = false;
      });
    }
  }
};
</script>

<style></style>
