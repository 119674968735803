<template>
  <b-form-select
    :options="itemsOptions"
    v-model="selectedOption"
    @change="updateItem($event)"
  ></b-form-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "addItemInput",
  props: ["row", "selected"],
  data() {
    return {
      itemName: "",
      selectedOption: this.selected
    };
  },
  methods: {
    ...mapActions(["setNewFormItem"]),
    updateItem(itemName) {
      var id = this.getAllItems
        .filter(i => i.name === itemName)
        .map(item => item.id)[0];
      var data = { lineNumber: this.row, id: id, data: itemName };
      this.$emit("update-item", data);
    }
  },
  computed: {
    ...mapGetters(["getAllItems", "getCurrentItem"]),
    itemsOptions: {
      get() {
        return this.getAllItems.map(x => {
          return { value: x.name, text: x.name };
        });
      }
    }
  }
};
</script>

<style></style>
