<template>
  <section>
    <b-table
      striped
      hover
      bordered
      head-variant="dark"
      outlined
      table-variant="light"
      responsive="sm"
      :items="order.orderLines"
      :fields="fields"
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
        <!-- <pre>{{ data.item }}</pre>  -->
      </template>

      <!-- wall -->
      <template #cell(description)="data">
        <p v-if="order.statusNo > '20' || !isAdmin">
          {{ data.item.description }}
        </p>
        <b-form-input
          v-if="order.statusNo <= '20' && isAdmin"
          v-model="data.item.description"
          required
          autofocus
          type="text"
        />
      </template>

      <!-- item name -->
      <!-- SpecialTxt -->
      <template #cell(name)="data">
        <p v-if="order.statusNo > '20' || !isAdmin">
          {{ data.item.name }} {{ data.item.specialTxt }}
        </p>

        <Add-Item-Input
          v-if="order.statusNo <= '20' && isAdmin"
          @update-item="updateItem"
          required
          :selected="data.item.name"
          :row="data.item.lineNumber"
        ></Add-Item-Input>
        <p v-if="isAdmin && showSpecialTxt">
          Special Txt:
          <special-txt-select
            :itemId="data.item.itemId"
            :selected="data.item.specialTxt"
            :rowId="data.item.lineNumber"
            @update-specialTxt="handleUpdateSpecialTxt"
          ></special-txt-select>
        </p>
      </template>

      <!-- qty -->
      <template #cell(qty)="data">
        <p v-if="order.statusNo > '20' || !isAdmin">
          {{ data.item.qty }}
        </p>
        <b-form-input
          v-if="order.statusNo <= '20' && isAdmin"
          v-model="data.item.qty"
          required
          style="min-width: 5rem"
          :state="qtyState(data.item.qty)"
          aria-describedby="input-live-help input-live-feedback"
          @change="updateItemChange(data.item)"
          type="number"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t("input.stateValidation.qty") }}
        </b-form-invalid-feedback>
      </template>

      <!-- Height -->
      <template #cell(height)="data">
        <p v-if="order.statusNo > '20' || !isAdmin">
          {{ data.item.height | formatNumber }}
        </p>
        <b-form-input
          v-if="order.statusNo <= '20' && isAdmin"
          v-model="data.item.height"
          required
          style="min-width: 6rem"
          :state="heightState(data.item.height)"
          @change="updateItemChange(data.item)"
          type="number"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t("input.stateValidation.height") }}
        </b-form-invalid-feedback>
      </template>

      <!-- Width -->
      <template #cell(width)="data">
        <p v-if="order.statusNo > '20' || !isAdmin">
          {{ data.item.width | formatNumber }}
        </p>
        <b-form-input
          v-if="order.statusNo <= '20' && isAdmin"
          v-model="data.item.width"
          required
          style="min-width: 6rem"
          :state="widthState(data.item.width)"
          @change="updateItemChange(data.item)"
          type="number"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t("input.stateValidation.width") }}
        </b-form-invalid-feedback>
      </template>

      <!-- sqrMeter -->
      <template #cell(sqrMeter)="data">
        <p>{{ data.item.sqrMeter | formatNumber }}</p>
      </template>

      <!-- Polishtype -->
      <template #cell(polishType)="data">
        <p v-if="!isAdmin">
          <Polish-Type :type="data.item.polishType"></Polish-Type>
        </p>
        <Polish-Options-Input-Select
          v-if="order.statusNo <= '20' && isAdmin && data.item.itemId"
          :selected="data.item.polishType"
          :rowId="data.item.lineNumber"
          :itemId="data.item.itemId"
          @update-polishType="handleUpdatePolishType"
        ></Polish-Options-Input-Select>
      </template>

      <!-- Attachments -->
      <template #cell(Attachment(s))="data">
        <div v-if="isUploading" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <b-form-file
            plain
            v-if="
              order.statusNo <= '20' &&
                isAdmin &&
                (data.item.attachmentUrl === null ||
                  data.item.attachmentUrl2 === null ||
                  data.item.attachmentUrl3 === null ||
                  data.item.attachmentUrl4 === null)
            "
            type="file"
            accept=".PDF,.PNG,.JPG,.JPEG,.DXF,.DWG,.SKP,.doc,.docx,application/vnd.ms-excel,application/msword;image/*;capture=camera"
            name="file"
            ref="file"
            class="mb-2"
            id="customFile"
            @change="fileChange($event, data)"
          />
          <b-button-group vertical size="sm">
            <b-button
              v-if="data.item.attachmentUrl"
              :id="data.item.attachmentUrl"
              :href="data.item.attachmentUrl"
              target="_blank"
            >
              <i class="fas fa-file-image"></i> {{ order.orderNo }}-{{
                data.index + 1
              }}-1
            </b-button>
            <b-button
              v-if="data.item.attachmentUrl2"
              :id="data.item.attachmentUrl2"
              :href="data.item.attachmentUrl2"
              target="_blank"
            >
              <i class="fas fa-file-image"></i> {{ order.orderNo }}-{{
                data.index + 1
              }}-2
            </b-button>
            <b-button
              v-if="data.item.attachmentUrl3"
              :id="data.item.attachmentUrl3"
              :href="data.item.attachmentUrl3"
              target="_blank"
            >
              <i class="fas fa-file-image"></i> {{ order.orderNo }}-{{
                data.index + 1
              }}-3
            </b-button>
            <b-button
              v-if="data.item.attachmentUrl4"
              :id="data.item.attachmentUrl4"
              :href="data.item.attachmentUrl4"
              target="_blank"
            >
              <i class="fas fa-file-image"></i> {{ order.orderNo }}-{{
                data.index + 1
              }}-4
            </b-button>
          </b-button-group>
        </div>
      </template>

      <template #cell(details)="data">
        <b-button
          @click="data.toggleDetails"
          class="mr-2"
          v-if="data.item.attachmentUrl"
        >
          {{ data.detailsShowing ? "Hide" : "Show" }} Details
        </b-button>
      </template>

      <template v-slot:row-details="data">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Item name:</b></b-col>
            <b-col>{{ data.item.name }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Height:</b></b-col>
            <b-col>{{ data.item.height }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Width:</b></b-col>
            <b-col>{{ data.item.width }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Polish:</b></b-col>
            <b-col>
              <Polish-Type :type="data.item.polishType"></Polish-Type>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Special Txt:</b></b-col>
            <b-col>{{ data.item.specialTxt }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Sqr Meter:</b></b-col>
            <b-col>{{ data.item.sqrMeter }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="12"> <b>Attachment(s):</b></b-col>
            <b-col sm="3">
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-if="data.item.attachmentUrl"
                  :href="data.item.attachmentUrl"
                  target="_blank"
                >
                  {{ order.projectName }} 1
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-if="data.item.attachmentUrl2"
                  :href="data.item.attachmentUrl2"
                  target="_blank"
                >
                  {{ order.projectName }} 2
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-if="data.item.attachmentUrl3"
                  :href="data.item.attachmentUrl3"
                  target="_blank"
                >
                  {{ order.projectName }} 3
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  :href="data.item.attachmentUrl4"
                  target="_blank"
                  v-if="data.item.attachmentUrl4"
                >
                  {{ order.projectName }} 4
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Created:</b></b-col>
            <b-col>{{ data.item.created | formatLongDateTime }}</b-col>
          </b-row>
        </b-card>
      </template>

      <template #cell(Edit)="data" v-if="order.statusNo <= '20' && isAdmin">
        <b-button-group vertical size="sm">
          <b-button variant="outline-secondary" @click="addSpecialTxt(data)"
            >SpecialTxt</b-button
          >
          <b-button
            variant="primary"
            @click="showEdit(data.item.id)"
            :disabled="!data.item.id"
            >Edit</b-button
          >
          <b-button variant="danger" @click="removeOrderLine(data.index)"
            >Delete</b-button
          >
        </b-button-group>
      </template>
    </b-table>
    <b-button
      v-if="order.statusNo <= '20' && isAdmin"
      variant="primary"
      @click="addRow"
      class="pull-right"
      ><i class="fas fa-plus"></i> Add row</b-button
    >
    <br />
    <br />
    <hr class="mt-4" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddItemInput from "@/components/AddItemInput";
import PolishType from "@/components/PolishType";
import PolishOptionsInputSelect from "@/components/PolishOptionsInputSelect";
import SpecialTxtSelect from "@/components/SpecialTxtSelect";

export default {
  name: "OrderLinesTable",
  components: {
    AddItemInput,
    PolishType,
    PolishOptionsInputSelect,
    SpecialTxtSelect
  },
  props: ["isAdmin", "order"],
  computed: {
    ...mapGetters([
      "getEditorOrderLineId",
      "getCurrentItem",
      "getCurrentOrder",
      "getCurrentOrderLine"
    ])
  },
  data() {
    return {
      isUploading: false,
      showSpecialTxt: false,
      fields: [
        {
          key: "#",
          label: "#"
        },
        {
          key: "description",
          label: "Wall"
        },
        {
          key: "name",
          label: "Glass type"
        },
        {
          key: "polishType",
          label: "Polish type"
        },
        {
          key: "qty",
          label: "Quantity"
        },
        {
          key: "height",
          label: "Height"
        },
        {
          key: "width",
          label: "Width"
        },
        {
          key: "sqrMeter",
          label: " m2"
        },
        {
          key: "Attachment(s)",
          class: "d-print-none"
        },
        {
          key: "Edit",
          label: this.order.statusNo <= "20" && this.isAdmin ? "Action" : ""
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      "setCurrentOrderLineId",
      "updateCreateCurrentOrder",
      "setCurrentOrderLine",
      "loadPolishTypeForItem",
      "addItemToOrder",
      "uploadFile",
      "addNewRow"
    ]),
    specialTxtOptions() {
      if (this.getCurrentItem.specialTxt) {
        return this.getCurrentItem.specialTxt.map(x => {
          return { value: x.name, text: x.name };
        });
      } else {
        return null;
      }
    },
    qtyState(qty) {
      if (qty >= 0 && qty <= 99) return true;
      return false;
    },
    heightState(height) {
      if (height >= 0 && height <= 5000) return true;
      return false;
    },
    widthState(width) {
      if (width >= 0 && width <= 2500) return true;
      return false;
    },
    updateSquare(lineNumber) {
      var orderLine = this.order.orderLines.filter(
        l => l.lineNumber === lineNumber
      )[0];
      if (orderLine.height > 0 && orderLine.height > 0 && orderLine.qty > 0) {
        orderLine.sqrMeter =
          (orderLine.qty * (orderLine.height * orderLine.width)) / 1000000;
      }
    },
    addSpecialTxt() {
      if (this.showSpecialTxt) this.showSpecialTxt = false;
      else this.showSpecialTxt = true;
    },
    addRow() {
      this.addNewRow();
    },
    removeItem(lineNumber) {
      this.order.orderLines[lineNumber].name = "";
    },
    fileChange(event, data) {
      var file = event.srcElement.files[0];
      var attachNumber = 0;

      var self = this;
      //orderLine
      var orderline = this.order.orderLines[data.item.lineNumber];
      if (orderline.attachmentUrl === null) {
        attachNumber = 1;
      } else if (orderline.attachmentUrl2 === null) {
        attachNumber = 2;
      } else if (orderline.attachmentUrl3 === null) {
        attachNumber = 3;
      } else if (orderline.attachmentUrl4 === null) {
        attachNumber = 4;
      }
      if (attachNumber != 0) {
        this.isUploading = true;
        var orderId = this.order.id;
        this.uploadFile({ file, orderId })
          .then(r => {
            if (attachNumber === 1) {
              orderline.attachmentUrl = r.data;
            } else if (attachNumber === 2) {
              orderline.attachmentUrl2 = r.data;
            } else if (attachNumber === 3) {
              orderline.attachmentUrl3 = r.data;
            } else if (attachNumber === 4) {
              orderline.attachmentUrl4 = r.data;
            }
          })
          .catch(e => {
            self.error = e;
          })
          .finally(() => {
            self.isUploading = false;
          });
      }
    },
    handleUpdatePolishType(value) {
      this.order.orderLines.filter(
        x => x.lineNumber === value.rowId
      )[0].polishType = value.data;
    },
    handleUpdateSpecialTxt(value) {
      this.order.orderLines.filter(
        x => x.lineNumber === value.rowId
      )[0].specialTxt = value.data;
    },
    removeOrderLine(index) {
      // TODO Add popup confirmation dialog
      if (index >= 0) {
        if (index === 0) {
          this.order.orderLines.splice(0, 1);
        } else {
          this.order.orderLines.splice(index, 1);
        }

        this.updateCreateCurrentOrder(this.order);
      }
    },
    updateItem(event) {
      var element = this.order.orderLines.filter(
        x => x.lineNumber === event.lineNumber
      )[0];
      element.itemId = event.id;
      element.name = event.data;
      element.polishType = "";
    },
    showEdit(id) {
      var self = this;
      this.updateCreateCurrentOrder(this.getCurrentOrder);
      let item = this.order.orderLines.filter(r => r.id === id)[0];
      if (item) {
        this.setEditItem(item).finally(() => {
          self.$bvModal.show("editItemModal");
        });
      }
    },
    async setEditItem(item) {
      if (item) {
        this.setCurrentOrderLine(item).then(() => {
          this.setCurrentOrderLineId(item.id);
          this.editItem = true;
        });
      }
    },
    updateItemChange(item) {
      this.updateSquare(item.lineNumber);
    }
  }
};
</script>

<style></style>
