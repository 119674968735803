<template>
  <b-card-group columns>
    <b-card
      v-for="(line, index) in order.orderLines"
      :key="line.id"
      class="overflow-hidden mt-2"
      style="max-width: 540px;"
    >
      <b-card-img
        :src="line.attachmentUrl"
        :alt="line.attachmentUrl"
        style="max-height:160px;"
        v-if="line.attachmentUrl"
      ></b-card-img>
      <b-card-body :title="line.name">
        <b-card-text> </b-card-text>
      </b-card-body>
      <b-list-group flush>
        <b-list-group-item
          ><strong>Item:</strong> {{ line.name }} {{ line.specialTxt || "" }}
        </b-list-group-item>
        <b-list-group-item
          ><strong>Polish type:</strong
          ><Polish-Type :type="line.polishType"></Polish-Type
        ></b-list-group-item>
        <b-list-group-item
          ><strong>Quanty:</strong> {{ line.qty }}</b-list-group-item
        >
        <b-list-group-item>
          <strong>H:</strong>
          {{ line.height | formatNumber }}
          <strong>W:</strong>
          {{ line.width | formatNumber }}
          <strong>sqr:</strong> {{ line.sqrMeter | formatNumber }} m2
        </b-list-group-item>

        <b-list-group>
          <b-list-group-item
            v-if="line.attachmentUrl"
            :href="line.attachmentUrl"
            id="card1"
            target="_blank"
          >
            <i class="fas fa-file-image"></i> File 1
            <b-tooltip target="card1" triggers="hover">
              <strong>Wall: </strong> {{ line.description }}
              <br />
              <strong>Filename:</strong>
              {{
                line.attachmentUrl.substring(
                  line.attachmentUrl.lastIndexOf("/") + 1
                )
              }}
            </b-tooltip>
          </b-list-group-item>

          <b-list-group-item
            v-if="line.attachmentUrl2"
            :href="line.attachmentUrl2"
            id="card2"
            target="_blank"
          >
            <i class="fas fa-file-image"></i> File 2
            <b-tooltip target="card2" triggers="hover">
              <strong>Wall: </strong> {{ line.description }}
              <br />
              <strong>Filename:</strong>
              {{
                line.attachmentUrl2.substring(
                  line.attachmentUrl2.lastIndexOf("/") + 1
                )
              }}
            </b-tooltip>
          </b-list-group-item>

          <b-list-group-item
            v-if="line.attachmentUrl3"
            id="card3"
            :href="line.attachmentUrl3"
            target="_blank"
          >
            <i class="fas fa-file-image"></i> File 3
            <b-tooltip target="card3" triggers="hover">
              <strong>Wall: </strong> {{ line.description }}
              <br />
              <strong>Filename:</strong>
              {{
                line.attachmentUrl3.substring(
                  line.attachmentUrl3.lastIndexOf("/") + 1
                )
              }}
            </b-tooltip>
          </b-list-group-item>

          <b-list-group-item
            :href="line.attachmentUrl4"
            id="card4"
            target="_blank"
            v-if="line.attachmentUrl4"
          >
            <i class="fas fa-file-image"></i> File 4
            <b-tooltip target="card4" triggers="hover">
              <strong>Wall: </strong> {{ line.description }}
              <br />
              <strong>Filename:</strong>
              {{
                line.attachmentUrl4.substring(
                  line.attachmentUrl4.lastIndexOf("/") + 1
                )
              }}
            </b-tooltip>
          </b-list-group-item>
        </b-list-group>
      </b-list-group>
      <template v-slot:footer>
        <small class="text-muted">Item nr: {{ index + 1 }}</small>
      </template>
    </b-card>
  </b-card-group>
</template>

<script>
import PolishType from "@/components/PolishType";

export default {
  name: "OrderLinesCard",
  components: {
    PolishType
  },
  props: ["order"]
};
</script>

<style></style>
